import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Creating indexes in DynamoDB allows you to index your data for a specific attribute(s), enabling you to create new access patterns. Let's assume that in your DynamoDB table, you are storing `}<strong parentName="p">{`Books`}</strong>{`. You may have your table set up like this:`}</p>
    <ul>
      <li parentName="ul">{`Partition key: Book Title`}</li>
      <li parentName="ul">{`Sort key: Publisher`}</li>
    </ul>
    <p>{`Each book may have different attributes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Author`}</inlineCode>{` (string)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Pages`}</inlineCode>{` (integer)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Language`}</inlineCode>{` (string)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`PublicationDate`}</inlineCode>{` (string)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ISBN10`}</inlineCode>{` (integer)`}</li>
    </ul>
    <p>{`If you want to access any book that a particular Author wrote, you can create an index for this attribute, which allows you to access any book that an Author wrote.`}</p>
    <p>{`Let's see how you can do that. This article will use `}<a parentName="p" {...{
        "href": "https://pypi.org/project/aioboto3/"
      }}>{`Python library aioboto3`}</a>{`.`}</p>
    <h2 {...{
      "id": "creating-the-index",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-the-index",
        "aria-label": "creating the index permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the index`}</h2>
    <p>{`You can create indexes by specifying an attribute as a partition key or two attributes, one for the partition key and one for the sort key. `}</p>
    <h3 {...{
      "id": "on-new-table-creation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#on-new-table-creation",
        "aria-label": "on new table creation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`On new table creation`}</h3>
    <p>{`If you are starting with a new table, you can create your index alongside your table. The only requirement is to specify the attribute you want to use in your index. Note that when making your indexes at Table creation, your partition key must have the Key Type `}<inlineCode parentName="p">{`HASH`}</inlineCode>{` .`}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`async def create_table_and_index()
    """Create DynamoDB table with index."""
    session = aioboto3.Session()
    async with session.resource("dynamodb") as dynamodb:
        table = await dynamodb.create_table(
            TableName="Books",
            KeySchema=[
                {"AttributeName": "BookTitle", "KeyType": "HASH"},  # Partition key
                {"AttributeName": "Publisher", "KeyType": "RANGE"},  # Sort key
            ],
            AttributeDefinitions=[
                #
                # Table attributes.
                #
                {"AttributeName": "BookTitle", "AttributeType": "S"},
                {"AttributeName": "Publisher", "AttributeType": "S"},
                #
                # Index attributes.
                #
                {"AttributeName": "Author", "AttributeType": "S"},
            ],
            GlobalSecondaryIndexes=[
                {
                    "IndexName": "author-index",
                    "KeySchema": [
                        {"AttributeName": "Author", "KeyType": "HASH"},
                        {"AttributeName": "Publisher", "KeyType": "RANGE"},
                    ],
                    "Projection": {
                        "ProjectionType": "ALL",
                    },
                }
            ],
            # There are required on table creation
            ProvisionedThroughput={
                "ReadCapacityUnits": 10,
                "WriteCapacityUnits": 10,
            },
        )

        await table.wait_until_exists()
`}</code></pre>
    <p>{`Let's look at what we are doing:`}</p>
    <ul>
      <li parentName="ul">{`Key Schema: These are our partition and short keys. The partition key must be of type `}<inlineCode parentName="li">{`HASH`}</inlineCode>{`. `}</li>
      <li parentName="ul">{`Attribute Definitions: Here, we can specify all the attributes. If you are starting from fresh, you should include your attributes at table creation. In this example, we only add `}<inlineCode parentName="li">{`Author`}</inlineCode>{` to create the index.`}</li>
      <li parentName="ul">{`Global Secondary Indexes:  This is a list of secondary indexes to be created. We need to give the index a name, a key schema and a Projection.`}</li>
    </ul>
    <p>{`For the `}<inlineCode parentName="p">{`KeySchema`}</inlineCode>{`, we included Author as the partition key and Publisher as the short key in, allowing us to query the index by Author to get all books or by Author and a specific Publisher.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`ProjectionType`}</inlineCode>{` we chose `}<inlineCode parentName="p">{`ALL`}</inlineCode>{` which will copy all the attributes into the index. Note that by doing this, you will consume double the space, but it means that you don't need to copy attributes manually. You can also choose which attributes to copy over with `}<inlineCode parentName="p">{`INCLUDE`}</inlineCode>{`.`}</p>
    <p>{`You may want to look at the `}<a parentName="p" {...{
        "href": "https://boto3.amazonaws.com/v1/documentation/api/latest/reference/services/dynamodb.html#DynamoDB.Client.create_table"
      }}>{`boto3 - create_table documentation`}</a>{` for a full description of all the things you can specify when creating your table and indexes.`}</p>
    <h3 {...{
      "id": "waiting-for-a-table",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#waiting-for-a-table",
        "aria-label": "waiting for a table permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Waiting for a table`}</h3>
    <p>{`You need to wait for the table to be created before you can use it. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`await table.wait_until_exists()
`}</code></pre>
    <p>{`This is why we are calling `}<inlineCode parentName="p">{`wait_until_exists`}</inlineCode>{`. This method will call to `}<inlineCode parentName="p">{`describe_table`}</inlineCode>{` every 20 seconds until either the table is created or it is called `}<inlineCode parentName="p">{`describe_table`}</inlineCode>{` 25 times (roughly after 8 minutes). An error is returned if the table isn't created after this time.`}</p>
    <h2 {...{
      "id": "updating-an-existing-table",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#updating-an-existing-table",
        "aria-label": "updating an existing table permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Updating an existing table`}</h2>
    <p>{`You can create an index on an existing table as well. There are a few ways to do it:`}</p>
    <ul>
      <li parentName="ul">{`Through the `}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/getting-started-step-6.html"
        }}>{`AWS console`}</a></li>
      <li parentName="ul">{`Using the AWS CLI`}</li>
      <li parentName="ul">{`Through another boto call`}</li>
    </ul>
    <h3 {...{
      "id": "using-the-aws-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-the-aws-cli",
        "aria-label": "using the aws cli permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the AWS CLI`}</h3>
    <p>{`If you have the AWS CLI installed on your machine, you can run the following command to create an index on your table:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`aws dynamodb update-table \\
    --table-name Books \\
    --attribute-definitions AttributeName=Author,AttributeType=S \\
    --global-secondary-index-updates \\
        "[{\\"Create\\":{\\"IndexName\\": \\"author-index\\",\\"KeySchema\\":[{\\"AttributeName\\":\\"Author\\",\\"KeyType\\":\\"HASH\\"}], \\
        \\"ProvisionedThroughput\\": {\\"ReadCapacityUnits\\": 10, \\"WriteCapacityUnits\\": 5      },\\"Projection\\":{\\"ProjectionType\\":\\"ALL\\"}}}]"
`}</code></pre>
    <h3 {...{
      "id": "using-aioboto3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-aioboto3",
        "aria-label": "using aioboto3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using aioboto3`}</h3>
    <p>{`If you prefer to use aioboto3 to update your table, you can use the following command. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`async def create_index()
    """Create DynamoDB index."""
    session = aioboto3.Session()
    async with session.resource("dynamodb") as dynamodb:
        table = await dynamodb.update_table(
            TableName="Books",
            AttributeDefinitions=[
                #
                # Table attributes.
                #
                {"AttributeName": "BookTitle", "AttributeType": "S"},
                {"AttributeName": "Publisher", "AttributeType": "S"},
                #
                # Index attributes.
                #
                {"AttributeName": "Author", "AttributeType": "S"},
            ],
            GlobalSecondaryIndexes=[
                {
                    "IndexName": "author-index",
                    "KeySchema": [
                        {"AttributeName": "Author", "KeyType": "HASH"},
                        {"AttributeName": "Publisher", "KeyType": "RANGE"},
                    ],
                    "Projection": {
                        "ProjectionType": "ALL",
                    },
                }
            ],
        )

        await table.wait_until_exists()
`}</code></pre>
    <p>{`As you can see, the code to create an index on an existing table is the same as the one that we use when creating a table.`}</p>
    <h2 {...{
      "id": "using-indexes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-indexes",
        "aria-label": "using indexes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Indexes`}</h2>
    <p>{`Now that we have seen how we can create indexes to query our data by a specific attribute. Let's see how we can query the index. You can use the `}<inlineCode parentName="p">{`query`}</inlineCode>{` method and specify which index to query by passing the index name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`async def get_author(author_name: str):
    """Get Author by using its index."""
    session = aioboto3.Session()
    async with session.resource("dynamodb") as dynamodb:
        result = await table.query(
            IndexName="author-index",
            ExpressionAttributeValues={
                ":author": author_name,
            },
            KeyConditionExpression=(
                "Author=:author"
            ),
        )
    
`}</code></pre>
    <p>{`In this example, we create a variable `}<inlineCode parentName="p">{`author`}</inlineCode>{` by using `}<inlineCode parentName="p">{`:`}</inlineCode>{` under the `}<inlineCode parentName="p">{`ExpressionAttributeValue`}</inlineCode>{`. We could call this anything we wanted, for example, `}<inlineCode parentName="p">{`:author_name`}</inlineCode>{`.`}</p>
    <p>{`Then under `}<inlineCode parentName="p">{`KeyConditionExpression`}</inlineCode>{`, we are saying that if `}<inlineCode parentName="p">{`Author`}</inlineCode>{` equals the variable `}<inlineCode parentName="p">{`:author`}</inlineCode>{`, return that. `}</p>
    <h2 {...{
      "id": "the-end",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-end",
        "aria-label": "the end permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The end`}</h2>
    <p>{`At first, when working with DynamoDB, indexes seemed difficult to understand - mostly because I didn't believe it would be this easy to create and query one.`}</p>
    <p>{`I hope this article is helpful for you and that you can use it in your DynamoDB journey!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      